import {getCurrentPageId} from '@oracle-cx-commerce/commerce-utils/selector';
import {parseCartSaveForLater} from './selectors-helpers';

export const getAbcfwsRepository = state => state.abcfwsRepository || {};

export const getCartSaveForLater = state => getAbcfwsRepository(state).cartSaveForLater || '';

export const getCartSaveForLaterParsed = state => parseCartSaveForLater(getCartSaveForLater(state)) || {};

export const getCheckoutMetadata = state => getAbcfwsRepository(state).checkoutMetadata || null;

export const getFulfillmentType = state => getAbcfwsRepository(state)?.fulfillmentType || '';

export const getPickupStore = state => getAbcfwsRepository(state)?.pickupStore || {};

export const getDeliveryAddress = state => getAbcfwsRepository(state)?.deliveryAddress || {};

export const getDeliveryStoreId = state => getAbcfwsRepository(state)?.deliveryStoreId || '';

export const getShippingRates = state => getAbcfwsRepository(state)?.shippingRates || {};

export const getFulfillmentInfoBeforeOrdersMerge = state =>
  getAbcfwsRepository(state)?.fulfillmentInfoBeforeOrdersMerge || {};

export const getOrobInventory = state => getAbcfwsRepository(state).orobInventory || {};

export const getOrobInventoryResults = (state, {pageId = getCurrentPageId(state)} = {}) =>
  getOrobInventory(state)[pageId] || {};
