export const parseCartSaveForLater = value =>
  value.split(',').reduce((acc, cur) => {
    const [productId, quantity] = cur.split(':');
    const parsedQuantity = parseInt(quantity, 10);

    if (!isNaN(parsedQuantity)) {
      acc[productId] = parsedQuantity;
    }

    return acc;
  }, {});
