import {getSessionContext} from '@oracle-cx-commerce/commerce-utils/selector';
import {
  getCartSaveForLater,
  getFulfillmentType,
  getDeliveryAddress,
  getDeliveryStoreId,
  getPickupStore,
  getShippingRates,
  getCheckoutMetadata,
  getFulfillmentInfoBeforeOrdersMerge
} from './abcfwsRepository';
import {getIsSiteOpen, getVaultSiteRepositoryLoaded} from './vault-site-repository';

export const getSaveToLocalStorage = state => {
  const session = getSessionContext(state);

  return {
    clientRepository: {
      context: {session}
    },
    abcfwsRepository: {
      cartSaveForLater: getCartSaveForLater(state),
      fulfillmentType: getFulfillmentType(state),
      deliveryAddress: getDeliveryAddress(state),
      deliveryStoreId: getDeliveryStoreId(state),
      pickupStore: getPickupStore(state),
      shippingRates: getShippingRates(state),
      checkoutMetadata: getCheckoutMetadata(state),
      getFulfillmentInfoBeforeOrdersMerge: getFulfillmentInfoBeforeOrdersMerge(state)
    },
    vaultSiteRepository: {
      isSiteOpen: getIsSiteOpen(state),
      isRepositoryLoaded: getVaultSiteRepositoryLoaded(state)
    }
  };
};
